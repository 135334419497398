import Head from 'next/head';
import { Center, Heading } from '@chakra-ui/react';

const NotFoundPage = () => (
  <>
    <Head>
      <title>Nicht gefunden</title>
    </Head>

    <Center height="100%">
      <Heading>Diese Seite wurde nicht gefunden!</Heading>
    </Center>
  </>
);

export default NotFoundPage;
